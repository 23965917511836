import React from 'react';

import Article from "../Article";
import Loader from "../General/Loader";

// Styles
import { ContentList } from './styles';

const ArticleList = (props) => {
    const CMSData = { posts: props.posts } || { posts: [] };
    return <ContentList>
        {
            !CMSData.posts
            ? <Loader />
            : CMSData.posts.map((element, index) => {
                return <Article key={index} info={ element } view="show" />
            })
        }
    </ContentList>
};

export default ArticleList;