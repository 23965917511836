import styled from "styled-components";
import {device} from '../../../styles/device'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 18px;
    @media ${device.tablet} {
        margin-top: 30px;
    }
`;

