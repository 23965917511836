import React, {Fragment} from 'react';
// Gatsby
import { navigate } from "gatsby";
// Styles
import {
    Container,
    Cat,
    CatImage,
    CatTitle,
    ArtContainer,
    ArtInfo,
    ArtDate,
    ArtDescription,
    LinkContainer,
    ArticleContent
} from './styles'
// Components
import Loader from '../General/Loader'
import Button from '../General/Button'
import { useNearScreen } from "../../hooks/useNearScreen";

const Article = (props) => {
    const { category, date, description, featuredimage, title, id, html } = props.info;
    const [show, element] = useNearScreen();

    if ( category ) {
        return (
        <>
            <Container ref={element} view={props.view} >
                {
                    show && <Fragment>
                        <Cat>
                            <CatImage src={ category.featuredimage } alt=""/>
                            <CatTitle>{ category.title }</CatTitle>
                        </Cat>
                        <ArtContainer source={ featuredimage } >
                            <ArtDate> { date } </ArtDate>
                            <ArtInfo view={props.view} >
                                <span style={{ fontWeight: "bold" }}>{ title }</span>
                                { (props.view === "show") && <ArtDescription>{ description }</ArtDescription> }
                            </ArtInfo>
                        </ArtContainer>
                        <LinkContainer>
                            {
                                (props.view === "show")
                                ? <Button
                                    title="LEER MAS"
                                    func={() => navigate(`/page/${id}`)}
                                />
                                : <></>
                            }
                        </LinkContainer>
                    </Fragment>
                }
            </Container>
            {

                (props.view !== "show")
                ? <div style={{textAlign: "center"}}>
                    <ArticleContent dangerouslySetInnerHTML={{ __html: html }} />
                </div>
                : <></>
            }
        </>
        )
    } else {
        return <Loader />
    }


};

export default Article;