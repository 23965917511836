import React from "react";

// Styles
import { Container, LoaderContainer } from "./styles";

const Loader = () => {
  return (
      <LoaderContainer>
          <Container>
              <div />
              <div />
              <div />
          </Container>
          <span> CARGANDO </span>
      </LoaderContainer>

  );
};

export default Loader;
