/* eslint-disable */
import { SET_ARTICLES, SET_CATEGORIES, SET_FT_ARTICLE } from "../types";

export const set_data = data => dispatch => {

  dispatch({
    type: SET_CATEGORIES,
    payload: data.posts
  });

  dispatch({
    type: SET_ARTICLES,
    payload: data.categories
  });

  dispatch({
    type: SET_FT_ARTICLE,
    payload: data.first_post
  });

};
