import styled from "styled-components";

export const Content = styled.div`
  cursor: pointer;
`;

export const Container = styled.div`
  max-width: 205px;
  max-height: 23px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #ff1071;
  border-radius: 25px;
  padding: 11px;
  position: relative;
  background: #fff;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -3;
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, #692b7c, #ff0068);
  }

  cursor: pointer;
`;
