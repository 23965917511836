import styled from "styled-components";
import { device } from '../../styles/device';

export const ContentList = styled.div`
    @media ${device.laptopL} {
        display: grid;
        justify-content: center;
        grid-template-columns: 690px 690px;
    }
    
    @media ${device.desktop} {
        display: grid;
        justify-content: center;
        grid-template-columns: 690px 690px;
    }
    
    @media ${device.laptopL} {
        display: grid;
        justify-content: center;
        grid-template-columns: 690px 690px;
    }
`;