import React, {useEffect} from "react";
import { connect } from "react-redux";

// Components
import { Pagination, Article, ArticleList } from '../components'

// Actions
import * as articlesActions from "../actions/articlesActions";

const IndexPage = (props) => {
  let { currentPage, numPages } = props.pageContext;
  const { set_data } = props;

  const CMSData = props.info || {
      posts: [],
      categories: [],
      first_post: {}
  };

  useEffect(() => {
      set_data(CMSData);
  }, [set_data, CMSData]);

  return (
      <div>
        <Article info={ CMSData.first_post } view="show" />
        { CMSData.posts && <ArticleList posts={ CMSData.posts } /> }
        <Pagination
            currentPage={currentPage}
            numPages={numPages} />
      </div>
  );
};

const mapStateToProps = reducers => {
  return reducers.articlesReducer;
};

export default connect(
  mapStateToProps,
  articlesActions
)(IndexPage);