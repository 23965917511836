import React from "react";

import { Link } from "gatsby";

// ICONS
import { FaArrowLeft, FaArrowRight, FaGripLinesVertical } from "react-icons/fa";

// Styles
import { Container } from "./styles";

const Pagination = ({currentPage, numPages}) => {
    const is_first = currentPage === 1;
    const is_last = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? "/page" : `/page/${ currentPage - 1 }`;
    const nextPage = `/page/${ currentPage + 1 }`;
    
    return (
        <Container>
            {
                !is_first && (
                    <Link to={prevPage}
                          rel="prev"
                          style={{ marginRight: "20px", textDecoration: "none", color:"#692b7c" }}>
                        <FaArrowLeft />
                        <FaGripLinesVertical />
                    </Link>
                )
            }
            <span>
                <strong>
                    Página { currentPage < 0? '1' : currentPage }
                </strong>
            </span>
            {
                !is_last && (
                    <Link to={nextPage}
                          rel="next"
                          style={{ marginLeft: "20px", textDecoration: "none", color:"#ff0068" }}>
                        <FaGripLinesVertical />
                        <FaArrowRight />
                    </Link>
                )
            }
        </Container>
    );
};

export default Pagination;