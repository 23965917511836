// GENERAL COMPONENTS
export { default as Loader } from "./General/Loader";
export { default as SEO } from "./General/Seo";
export { default as Footer } from "./General/Footer";
export { default as Pagination } from "./General/Pagination";

// ARTICLES COMPONENTS
export { default as Article } from "./Article";
export { default as ArticleList } from './ArticleList';

