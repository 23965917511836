import { keyframes, css } from 'styled-components'

const fadeInKeyframes = keyframes`
  from {
    filter: blur(5px);
    opacity: 0;
  }

  to {
    filter: blur(0);
    opacity: 1;
  }
`

export const fadeIn = ({ time = '2s', type = 'ease' } = {}) =>
    css`animation: ${time} ${fadeInKeyframes} ${type};`;

const focusKeyFrame = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale( 1.5 );
  }
`;

export const focus = ({ time = '1s', type = 'linear' } = {}) =>
    css`animation: ${time} ${focusKeyFrame} ${type};`;