import styled, { css } from "styled-components";
import { device } from '../../styles/device';
import { fadeIn } from '../../styles/animation';

export const Container = styled.div`
    ${fadeIn()}
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-bottom: 20px;
    transition: 2s;
    
    ${props => (props.view === "show") && css`
        &:hover {
            transform: scale(0.95);
        }
    `}
`;

export const Cat = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`;

export const CatImage = styled.img`
    width: 47px;
`;

export const CatTitle = styled.div`
    padding: 15px;
`;

export const ArtContainer = styled.div`
    background-image: url(${props => props.source });
    background-color: #cccccc;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    height: 450px;
    max-width: 90%;
    
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    ${props => (props.view !== "show") && css`
        width: 100%;
    `}
`;

export const ArtDate = styled.span`
    text-align: right;
    padding-right: 13px;
    padding-bottom: 10px;
    color: #FFF;
    font-weight: bold;
`;
export const ArtInfo = styled.div`
    background-color: #6f2f82;
    color: #FFF;
    padding: 13px;
    max-height: 130px;
    
    ${props => (props.view !== "show") && css`
        height: 13%;
    `}
`;

export const ArtDescription = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    height: 71%;
    text-align: justify;
    
    @media ${device.tablet} {
        height: 57%;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    width: 88%;
    justify-content: flex-end;
    position: relative;
    top: -10px;
    
    & > div {
        width: 140px;
    }
`;

export const ArticleContent = styled.article`
    max-width: 90%;
    text-align: justify;
    display: inline-block;
    margin-bottom: 50px;
`;