import React from "react";
import propTypes from "prop-types";
// Styles
import { Container, Content } from "./styles";

const Button = ({ title, func }) => {
  return (
    <Content>
      <Container onClick={func}>{title}</Container>
    </Content>
  );
};

Button.defaultProps = {
  title: ``,
  func: () => {}
};

Button.propTypes = {
  title: propTypes.string,
  func: propTypes.func
};

export default Button;
