import React from "react";

// import icons
import {
  FaTwitter,
  FaFacebook,
  FaGooglePlus,
  FaEnvelope,
  FaPinterest,
  FaLinkedin
} from "react-icons/fa/";

// Styles
import { Content } from "./styles";

import { ShareButtonCircle, ShareBlockStandard } from "react-custom-share";

const Footer = ({ url, title, description, img }) => {
  const shareBlockProps = {
    url: `${url}`,
    button: ShareButtonCircle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "GooglePlus", icon: FaGooglePlus },
      { network: "Email", icon: FaEnvelope },
      {
        network: "Pinterest",
        icon: FaPinterest,
        media: { img }
      },
      { network: "Linkedin", icon: FaLinkedin }
    ],
    text: title,
    longtext: description
  };

  return (
    <Content>
      <ShareBlockStandard {...shareBlockProps} />
    </Content>
  );
};

export default Footer;
